<template>
  <base-card dark class="kv">
    <video playsinline autoplay muted loop>
      <source src="@/assets/top/web.mp4" />
    </video>
    <h1>
      <div class="glitch" data-text="ゴメスノナハゴメス">
        ゴメスノナハゴメス
      </div>
    </h1>
    <div class="bottom"></div>
  </base-card>
</template>

<script>
export default {
  name: "HomeBanner",
};
</script>

<style lang="scss"></style>
